// App.tsx
import React from 'react';
import SwotGraph from './SwotGraph.tsx';

const App = () => {
    const handleQuadrantClick = (quadrant: string) => {
        console.log(`Clicked on quadrant: ${quadrant}`);
        // You can add specific logic for each quadrant here
    };

    return (
        <div className="App">
            <SwotGraph onClickQuadrant={handleQuadrantClick} />
        </div>
    );
};

export default App;
