// src/SwotGraph.tsx
import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

interface SwatGraphProps {
    onClickQuadrant: (quadrant: string) => void;
}

const SwotGraph: React.FC<SwatGraphProps> = ({ onClickQuadrant }) => {
    const handleQuadrantClick = (quadrant: string) => {
        onClickQuadrant(quadrant);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={6} onClick={() => handleQuadrantClick('Strengths')}>
                <Paper sx={{ backgroundColor: 'lightblue', padding: 2 }}>
                    <Typography variant="h5">Strengths</Typography>
                </Paper>
            </Grid>
            <Grid item xs={6} onClick={() => handleQuadrantClick('Weaknesses')}>
                <Paper sx={{ backgroundColor: 'lightcoral', padding: 2 }}>
                    <Typography variant="h5">Weaknesses</Typography>
                </Paper>
            </Grid>
            <Grid item xs={6} onClick={() => handleQuadrantClick('Opportunities')}>
                <Paper sx={{ backgroundColor: 'lightgreen', padding: 2 }}>
                    <Typography variant="h5">Opportunities</Typography>
                </Paper>
            </Grid>
            <Grid item xs={6} onClick={() => handleQuadrantClick('Threats')}>
                <Paper sx={{ backgroundColor: 'lightyellow', padding: 2 }}>
                    <Typography variant="h5">Threats</Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default SwotGraph;
